<template>
<div class="page-retirar-caja">
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </v-container>

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <base-material-card color="primary" icon="library_books" title="Retirar Caja" class="elevation-1 px-5 py-3">

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
              <v-row>                
                <v-col sm="3" md="3" lg="3">
                    <v-menu offset-x ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                        <template v-slot:activator="{ on }">
                            <v-text-field v-on="on" v-model="model.fecha_de" label="Ventas a partir del día" color="secondary" prepend-icon="event"
                            @blur="model.fecha_de = parse_date(model.fecha_de)" :rules="[rules.required, valida_fecha]">
                            </v-text-field>
                        </template>

                        <v-date-picker v-model="model.fecha_de" color="secondary" scrollable locale="es-mx" @input="menu1 = false">
                            <v-spacer />

                            <v-btn color="secondary" x-small @click="menu1 = false">
                            Cancelar
                            </v-btn>
                        </v-date-picker>
                        </v-menu>
                </v-col>                                   
                <v-col sm="4" md="4" lg="4">
                    <v-autocomplete
                            v-model="model.sucursal" :items="sucursales" :hide-no-data="true"
                            placeholder="Selecione una sucursal"
                            item-text="nombre" item-value="_id"
                            :rules="[rules.required]"
                            @change="buscar()"
                            label="Sucursal">
                        </v-autocomplete>
                </v-col>
                <v-col sm="3" md="3" lg="3">
                    <v-btn color="success" @click.native="buscar()">
                        <v-icon>done</v-icon> Buscar Ventas
                    </v-btn>
                </v-col>
            </v-row>
              <v-row>                    
                <v-col sm="3" md="3" lg="3">
                    <v-autocomplete
                            v-model="model.serie" :items="series_empleado" :hide-no-data="true"
                            :loading="isLoadingSeries"
                            placeholder="Selecione una o más series"
                            small-chips deletable-chips
                            item-text="val" item-value="val"
                            multiple                        
                            label="Serie">
                        </v-autocomplete>                    
                </v-col>
                <v-col sm="2" md="2" lg="2">
                    <v-btn color="info" @click.native="filtrar_series()">
                        Filtrar Series
                    </v-btn>
                </v-col>                
              
            </v-row>

          </v-container>
        </v-form>
      </v-card-text>
      
      <v-card-text>
        <div style="text-align:center"><h5>{{ nombreEmpleado }}</h5></div>
        <v-row>
            <v-col sm="12" lg="12" md="12">
                <h5>Ventas</h5>
                <!--div id="tableEfectivo" style="height: 70vh; overflow-y: scroll; overflow-x: scroll;"-->
                    <v-client-table :data="efectivo_items" :columns="columns" :options="options">
                        <template slot="noResults">
                            <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                        </template>
                        <template slot="subtotal" slot-scope="props">
                            <div style="text-align:right !important;">{{ formatNumber(parseFloat(props.row.subtotal).toFixed(2))}}</div>
                        </template>
                        <template slot="impuestos" slot-scope="props">
                            <div style="text-align:right !important;">{{ formatNumber(parseFloat(props.row.impuestos).toFixed(2))}}</div>
                        </template>
                        <template slot="total" slot-scope="props">
                            <div style="text-align:right !important;">{{ formatNumber(parseFloat(props.row.total).toFixed(2))}}</div>
                        </template>
                        <template slot="efectivo" slot-scope="props">
                            <div style="text-align:right !important;">{{ formatNumber(parseFloat(props.row.efectivo).toFixed(2))}}</div>
                        </template>
                        <template slot="voucher" slot-scope="props">
                            <div style="text-align:right !important;">{{ formatNumber(parseFloat(props.row.voucher).toFixed(2))}}</div>
                        </template>
                        <template slot="transferencia" slot-scope="props">
                            <div style="text-align:right !important;">{{ formatNumber(parseFloat(props.row.transferencia).toFixed(2))}}</div>
                        </template>
                        <template slot="cheques" slot-scope="props">
                            <div style="text-align:right !important;">{{ formatNumber(parseFloat(props.row.cheques).toFixed(2))}}</div>
                        </template>
                    </v-client-table>
                <!--/div-->
                <div style="text-align:right !important;">
                    <table border="0" width="100%">
                        <tr style="font-size:16px; font-weight:bold;">
                            <td width="85%">Efectivo:</td>
                            <td>$ {{ formatNumber(parseFloat(total_efectivo).toFixed(2))}}</td>
                        </tr>
                        <tr style="font-size:16px; font-weight:bold;">
                            <td>Voucher:</td>
                            <td>$ {{ formatNumber(parseFloat(total_voucher).toFixed(2))}}</td>
                        </tr>
                        <tr style="font-size:16px; font-weight:bold;">
                            <td>Transferencias:</td>                            
                            <td>$ {{ formatNumber(parseFloat(total_transferencia).toFixed(2))}}</td>
                        </tr>
                        <tr style="font-size:16px; font-weight:bold;">
                            <td>Cheques:</td>                            
                            <td>$ {{ formatNumber(parseFloat(total_cheques).toFixed(2))}}</td>
                        </tr>
                        <tr style="font-size:16px; font-weight:bold;">
                            <td>TOTAL:</td>
                            <td>$ {{ formatNumber(parseFloat(total).toFixed(2))}}</td>
                        </tr>
                        <tr style="font-size:16px; font-weight:bold;">
                            <td>-------------</td>
                            <td>-------------</td>
                        </tr>
                        <tr style="font-size:16px; font-weight:bold;">
                            <td>TOTAL VENTAS NO FACTURADAS:</td>
                            <td>$ {{ formatNumber(parseFloat(total_no_facturada).toFixed(2))}}</td>
                        </tr>
                        <tr style="font-size:16px; font-weight:bold;">
                            <td>TOTAL VENTAS FACTURADAS:</td>
                            <td>$ {{ formatNumber(parseFloat(total_facturada).toFixed(2))}}</td>
                        </tr>                        
                    </table>
                </div>
            </v-col>
        </v-row>        
        <v-row>
            <hr style="height: 1px !important; background: #333;">
        </v-row>

        <v-row>
            <v-col sm="12" lg="12" md="12">
                <div style="text-align:right !important; color:red;">
                    <v-btn dark 
                        v-show="parseFloat(total) > 0" color="red" @click.native="validaRetiro()" >
                        <v-icon small>delete</v-icon> &nbsp;Retirar Caja
                    </v-btn>                                  
                </div>                
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="12" lg="12" md="12">
                <div style="text-align:right !important; color:red;">
                    Utilizar esta opción cuando la caja a retirar ya no esté en operación, ya que puede ocasionar inconsistencias en la información.
                </div>                
            </v-col>
        </v-row>

      </v-card-text>


        </base-material-card>
      </v-col>
    </v-row>
  </v-container>   

  <!-- MODAL CAJA -->
    <v-dialog v-model="modal_caja" max-width="35%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">edit</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Caja a la que se moverán ventas facturadas</strong>
                    </span>
                </v-card>                
            </v-card-title>
            <v-card-text>                
                    <v-container grid-list-md>
                        <br>
                        <v-form ref="formcaja" lazy-validation>
                        <v-row>
                            <v-col sm="12" md="12" lg="12">
                                <v-text-field label="Ingrese Letra de Caja" 
                                    @input="caja_seleccionada = caja_seleccionada.toString().toUpperCase()"
                                    maxlength=1
                                    ref="ingresaCaja"
                                    v-model="caja_seleccionada" 
                                    :rules="[rules.required, valida_caja]">
                                </v-text-field>
                            </v-col>
                        </v-row>  
                        </v-form>                      
                    </v-container>                
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click.native="modal_caja=false">
                    <v-icon>cancel</v-icon> Cerrar
                </v-btn>
                &nbsp;&nbsp;
                <v-btn color="success" @click.native="saveRetiro()">
                    <v-icon>done</v-icon> Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</div>
</template>

<script>

export default {
  components: {
  },
  watch: {
    model_filters: {
      handler(val) {
        this.$nextTick(() => {
          this.items = [];
          this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        });
      },
      deep: true
    },
    search_empleado(val) {
        //Si no ha escrito 2 letras o mas
        if (val == null || val.length<2 ) return
        // Si todavía esta buscando
        if (this.isLoading) return
        this.isLoading = true
        let data = {
                    "selector": {
                        "type":"user",
                        "nombre": {"$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")},
                        "estatus": "Activo"
                    },
                    // "sort": ["_id"],
                };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/_users/_find/',data)
                .then(response => {
                    if (response.data.docs.length > 0){
                        this.empleados = response.data.docs;
                    }else
                        this.empleados = [];
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los empleados.",
                        footer: ""
                    });
                }).then(()=>{ this.isLoading=false; });
      },


  },
  mounted: function() {
    if (!this.verificaPermiso('t.cortes.cortes')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    this.model = this.clean_model();
    var today = window.moment().format("YYYY-MM-DD");
    this.model.fecha_de = today;
    this.get_sucursales();
  },
  data() {
    return {
        cajas_autoincrementable:[],
        caja_seleccionada:"",
        modal_caja: false,
        series_empleado:[],      
        series_empleado_array:[],  
        model_corte:{},        
        nombreEmpleado:"",
        total_efectivo:0,
        total_voucher:0,
        total_transferencia:0,
        total_cheques:0,
        total:0,
        total_no_facturada:0,
        total_facturada:0,        
        efectivo_items:[],
        columns: ["sucursal.nombre","caja","folio","fecha","subtotal","impuestos","total","efectivo","voucher","transferencia","cheques","estatus"],
        options:{
            perPage: 10,
            pagination: {
                show: true
            },
            headings:{
                "sucursal.nombre":"Sucursal",
                "caja":"Caja Cobro"
            },
        },
        sucursales:[],
        menu1:false,
        menu2:false,
        menu3:false,
        menu4:false,
      search_empleado:'',
      search_marca: null,
      tipos_precios: [],
      categorias:[],
      marcas:[],
      empleados:[],
      articulos_proveedores:[],
      fab: [],
      isLoading: false,
      isLoadingSeries: false,
      articulos_encontrados: [],
      id_cliente: "",

      search: "",
      model_filters: "",

      tmp_fecha: "",
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Cortes",
          disabled: true,
          href: ""
        },
        {
          text: "Retirar Caja",
          disabled: true,
          href: ""
        }
      ],

      modal: false,
      modal_articulos: false,
      update: false,
      model: {},
      rules: {
        required: v => !!v || "Este campo es requerido",
        decimal: value => {
          const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
          return pattern.test(value) || "Número decimal no válido";
        },
        entero: function(numero) {
          const pattern = /^[\d]*$/;
          return pattern.test(numero) || "Debe ser un número entero";
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Correo electrónico no valido";
        },
        rfc: v =>
          (v && v.length >= 9 && v.length < 15) || "RFC debe tener al menos 9 caractéres y 14 caractéres"
      },
      items: []
    }
  },
  methods: {
    get_sucursal: function(id_sucursal){
        var filtro = this.sucursales.find(e => e._id == id_sucursal);
        if(filtro){
            return filtro;
        } else{
            return false;
        } 

    },
    valida_caja: function(val){
        const pattern = /^[\a-zA-Z]*$/;
        if (val != null && val != "" && !pattern.test(val)) {
            return "Letra no válida";
        }

        //Si filtró por serie/caja que no sea la misma caja
        if(this.model.serie.length > 0){
            if(this.model.serie.includes(val)){
                return "No puede ser la misma caja a la que se hace retiro";
            }
        } else {//Si no filtró por serie, que no sea ninguna de las del empleado
            if(this.series_empleado_array.includes(val)){
                return "No puede ser la misma caja a las que se hace retiro";
            }
        }
        
        return true;
    },
    hay_inventario_comprometido:function(){

    },
    validaRetiro: function(){
        //Checa primero si hay inventario comprometido, si hay no deja hacer el retiro de caja
        window.dialogLoader.show('Espere un momento por favor..');

        window.axios
          .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/ventas/_view/ventas_sin_salida')
          .then(async response => { 
            if(response.data != null && response.data.rows!=null && response.data.rows.length>0){
                window.dialogLoader.hide();
                this.$swal({
                    type: "info",
                    title: "¡Operación no permitida!",
                    text: "Hay inventario comprometido. Primero debe dar salida a las ventas, y luego podrá hacer retiro de caja.",
                    footer: "",                
                });
            } else {
                window.dialogLoader.hide();
                this.caja_seleccionada = "";
                if(this.total_facturada > 0){
                    //mostrar modal y solicitar la caja a donde se enviaran las ventas facturadas
                    this.modal_caja = true;
                    setTimeout(() => {
                        this.$nextTick(() => this.$refs.ingresaCaja.focus());
                    }, 100);
                } else {
                    this.saveRetiro();
                }
            }
          }).catch(err => {
            console.log(err);
            window.dialogLoader.hide();
            this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al obtener las ventas sin salida de mercancía. Intente nuevamente",
                  footer: ""
              });
          });
             
    },
    
    saveRetiro:async function(){
        //Las ventas no facturadas se eliminan, y las ventas facturadas hay que preguntar a que caja quieren pasarlas
        // y las ventas facturadas se van a generar en la caja elegida pero con un nuevo folio
        // luego se tiene que regresar el autoincrementable de la sucursal y caja para que continue desde donde se eliminó
        // si se eliminó del folio 55 al 60, entonces el autoincrementabe debe quedar en 54 para continuar
        if(this.total_facturada > 0 && !this.$refs.formcaja.validate() ){

        } else {
            this.modal_caja = false;
            var mensaje = "Se eliminarán las ventas. ¿Desea continuar?"
            if(this.total_facturada > 0){
                mensaje = "Se eliminarán las ventas no facturadas, y las facturadas se pasarán a la caja "+ this.caja_seleccionada +". ¿Desea continuar?"
            }
            
            this.$swal({
                type: "info",
                title: "Cuidado",
                text: mensaje,
                footer: "",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Si",
                cancelButtonText: "No"
            }).then(async resultado => {
                if (resultado.value) {

                    window.dialogLoader.show('Espere un momento por favor..');   
                                    
                    var self = this;       
                    
                    //Se buscan todas las ventas, incluyendo Canceladas y En Proceso, para eliminarse
                    let data = {
                        "selector": {
                            "folio_int": {"$exists": true},
                            "usuario_venta": {"$exists": true},
                            "type":"ventas",
                            "estatus": {
                                "$in":["Cancelada","En Proceso","Finalizada","Facturada","Factura Global"]
                            },
                            "sucursal._id": this.selector_ventas["selector"]["sucursal._id"],
                            "fecha": this.selector_ventas["selector"]["fecha"]                            
                        },
                        "use_index": "_design/444424c44b20a289edc0c43ab02c7d3032b158ca"
                    };

                    if(this.selector_ventas["selector"]["caja"]){
                        data["selector"]["caja"] = this.selector_ventas["selector"]["caja"];
                    }
                    
                    this.cajas_autoincrementable = [];
                    var errores = 0;
                
                    
                    await window.axios
                    .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                    .then(async response => {

                        if (response.data.docs.length > 0){
                            
                            for(var k in response.data.docs){
                                var x = response.data.docs[k];
                                //Llenamos el arreglo con la caja, anio y su folio menor, para luego regresar el folio autoincrementable de la caja
                                self.busca_menor_autoinc(this.cajas_autoincrementable, x);

                                if(["Cancelada","En Proceso","Finalizada"].includes(x["estatus"])){//Se elimina

                                    //x.type = "ventas_retiradas";                                   
                                    //console.log("Venta retirada: ", x);
                                    //Primero lo traemos para que traiga su ultima version
                                    await window.axios
                                        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + x._id)
                                        .then(async responseVenta => {
                                            var venta = responseVenta.data;
                                            await window.axios
                                                .delete(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE+ "/" + venta._id + "?rev=" + venta._rev)
                                                .then(rDelete => {
                                                    
                                                }).catch(error => {
                                                    console.log("Error al eliminar venta: ", error );
                                                    errores += 1;
                                                });
                                        }).catch(error => {
                                            console.log("Error al obtener venta: ", x._id)
                                        });
                                    

                                } else {//facturadas se envian a la caja seleccionada con nuevo folio
                                    
                                    //console.log("Venta Movida: ", x);
                                    var anio = self.parse_date_anio(x.fecha);
                                    var dataConsecutivo={
                                        "id_sucursal": x.sucursal._id, //requerido
                                        "anio":  anio, //requerido
                                        "caja": self.caja_seleccionada, //requerido                              
                                    };
                                    await window.funciones.getConsecutivoCaja(dataConsecutivo)
                                    .then( async resultConsecutivo =>{

                                        var ticket_anterior = x.ticket.toString().toUpperCase();
                                        x['ticket_anterior'] = ticket_anterior;
                                        x.folio = resultConsecutivo.toString();
                                        x.folio_int = parseInt(resultConsecutivo);
                                        x.ticket = ticket_anterior.toString().substring(0,6) + self.caja_seleccionada.toString().toUpperCase() + resultConsecutivo.toString();
                                        x.caja = self.caja_seleccionada.toString().toUpperCase();
                                        x.caja_venta = self.caja_seleccionada.toString().toUpperCase();

                                        await window.axios
                                            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + x._id + '?conflicts=true', x)
                                            .then(rupdate => {
                                                //console.log("Se movio venta facturada "+ticket_anterior+" a nueva caja "+self.caja_seleccionada);
                                            })
                                            .catch(error => {
                                                console.log("Error al guardar nuevo folio de venta facturada: ", error);
                                                errores += 1;
                                            });

                                    }).catch( err => {
                                        console.log("Error al obtener consecutivo: ", err);
                                        errores += 1;
                                    });

                                }
                            }    
                            
                            if (this.cajas_autoincrementable.length > 0){//Actualizamos folios de consecutivos de Caja
                                
                                for(var k in this.cajas_autoincrementable){
                                    var caja = this.cajas_autoincrementable[k];
                                    //console.log("Sucursal: "+caja["id_sucursal"]+"  Caja: "+caja['caja']+"  Anio: "+caja['anio']+"  Inc: "+caja['folio']);
                                    var query = {'selector': {
                                            'type': 'autoincrementable_caja', 
                                            'id_sucursal': caja["id_sucursal"],
                                            'anio': parseInt(caja["anio"]),
                                            'caja': caja["caja"]
                                        }, 
                                        'limit': 1,
                                        'use_index':'_design/c819de007768959d3507eee9b508c8295042f72b'
                                    };
                                    await window.axios
                                        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', query)
                                        .then(async responseCajas => {
                                            if (responseCajas.data.docs.length > 0){
                                                var folio = responseCajas.data.docs[0];
                                                folio["folio"] = parseInt(caja["folio"]) - 1; //Se resta 1 para que el siguiente folio sea donde se quedó
                                                await window.axios
                                                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + folio._id + '?conflicts=true', folio)
                                                .then(rfolio => {
                                                    
                                                })
                                                .catch(error => {
                                                    console.log("Error al guardar nuevo folio en consecutivo de caja: ", error);
                                                    errores += 1;
                                                });
                                            }
                                        }).catch( error =>{
                                            console.log("Error al buscar folio de caja: ", error);
                                            errores += 1;
                                        });
                                }

                                
                            }
                        }

                        
                    }).catch(error => {
                        console.log("Error al consultar ventas: ", error);
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al consultar las ventas. Intente nuevamente",
                            footer: ""
                        });
                        window.dialogLoader.hide();
                    }).then(()=>{
                        window.dialogLoader.hide();
                        window.dialogLoader.showSnackbar('El retiro se realizó correctamente..', {
                            color: 'success'
                        });
                        this.buscar();
                        
                    });                    

                    
                    
                }
            });
        }
        
    },
    setNombreEmpleado: function(){
        var self = this;
        var filtro = this.empleados.find(function(e){
            return e.name == self.model.id_empleado;
        });

        if(filtro)
            self.nombreEmpleado = filtro.nombre;
        else
            self.nombreEmpleado = "";
    },
    validaCantidadFloatCero: function (value) {
        const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
        if (value != null && value != "" && !pattern.test(value)) {
            return "Cantidad no válida"
        } else if (value != null && value != "") {
            if (parseFloat(value) <= 0)
                return true;
        }
        return true;
    },
    
    filtrar_series:function(){
        //console.log("Series: ", this.model.serie);
        this.buscar(this.model.serie);
    },
    
    buscar: function (series_filtro = []) {
        if(this.$refs.form.validate()){
            try {
                window.dialogLoader.show('Espere un momento por favor..');
                var self = this;

                var today = window.moment().format("YYYY-MM-DD");
                let data = {
                    "selector": {
                        "type":"ventas",
                        //"estatus": {"$in":["Finalizada","Facturada","Factura Global"]},
                        "sucursal._id": self.model.sucursal,
                        "caja": {
                            "$exists": true
                        },
                        "fecha": {
                            "$gte": self.model.fecha_de,
                            "$lte": today+"T23:59:59.999"
                        },                        
                    },
                    "fields": [
                        "_id", "caja", "folio", "folio_int", "fecha","subtotal","impuestos","total","pago","cambio","sucursal","estatus"
                    ],
                    "use_index": "_design/6dc13b4c64187763f1b75ca973195e40a4289b0d"
                };

                
                if(series_filtro.length > 0){
                    data["selector"]["caja"] = {"$in": series_filtro};
                } else {
                    this.series_empleado = [];
                    this.series_empleado_array = [];
                    self.model.serie = [];
                    self.isLoadingSeries = true;
                }

                this.selector_ventas = JSON.parse(JSON.stringify(data));//Resguardamos busqueda para el momento de eliminar ventas
                

                window.axios
                    .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                    .then(response => {
                        
                        this.efectivo_items = [];
                        self.cortes_items = [];
                        this.total_efectivo = 0;
                        this.total_voucher = 0;
                        this.total_transferencia = 0;
                        this.total_cheques = 0;
                        this.total = 0;
                        this.total_no_facturada = 0;
                        this.total_facturada = 0;                        
                        this.retirado_efectivo = 0;
                        this.retirado_voucher = 0;
                        this.retirado_transferencia = 0;
                        this.retirado_cheques = 0;
                        this.retirado_total = 0;
                        this.restante_efectivo = 0;
                        this.restante_voucher = 0;
                        this.restante_transferencia = 0;
                        this.restante_cheques = 0;
                        this.restante_total = 0;
                        
                        if (response.data.docs.length > 0){
                            /*response.data.docs.sort(function (a, b) {
                                //Ordenar por fecha más reciente
                                return new Date(b.fecha) - new Date(a.fecha);
                            });*/
                            this.efectivo_items = response.data.docs;

                            //Calculos
                            for(var k in this.efectivo_items){

                                var x = this.efectivo_items[k];

                                if(series_filtro.length == 0){
                                    this.series_empleado.push({"val":x.caja});                                    
                                }

                                x["fecha"] = self.parse_date_time_mx(x["fecha"]);
                                var efectivo = 0;
                                var voucher = 0;
                                var transferencia = 0;
                                var cheques = 0;
                                for(var kp in x.pago){                                    
                                    if(x.pago[kp].metodo.toString().toLowerCase().indexOf("efectivo") != -1){
                                        efectivo += parseFloat(x.pago[kp].cantidad);
                                    } else if(x.pago[kp].metodo.toString().toLowerCase().indexOf("cheque") != -1){
                                        cheques += parseFloat(x.pago[kp].cantidad);
                                    } else if(x.pago[kp].metodo.toString().toLowerCase().indexOf("transferencia") != -1){
                                        transferencia += parseFloat(x.pago[kp].cantidad);
                                    } else {
                                        voucher += parseFloat(x.pago[kp].cantidad);
                                    }
                                }
                                x["efectivo"] = efectivo - x.cambio; //Al pago de efectivo se resta el cambio
                                x["voucher"] = voucher;
                                x["transferencia"] = transferencia;
                                x["cheques"] = cheques;
                                self.total_efectivo += efectivo - x.cambio;
                                self.total_voucher += voucher;
                                self.total_transferencia += transferencia;
                                self.total_cheques += cheques;
                                self.total += efectivo - x.cambio;
                                self.total += voucher;
                                self.total += transferencia;   
                                self.total += cheques;   
                                if( ["Facturada","Factura Global"].includes(x["estatus"])){                                    
                                    self.total_facturada += efectivo - x.cambio;
                                    self.total_facturada += voucher;
                                    self.total_facturada += transferencia;
                                    self.total_facturada += cheques;   
                                } else {
                                    self.total_no_facturada += efectivo - x.cambio;
                                    self.total_no_facturada += voucher;
                                    self.total_no_facturada += transferencia;
                                    self.total_no_facturada += cheques;
                                }                                
                            }                            

                            this.series_empleado_array = this.series_empleado.map(e => e.val);   
                            this.series_empleado_array = this.series_empleado_array.filter(this.onlyUnique);
                            //console.log("ARR: ", this.series_empleado_array);

                        } else {
                            window.dialogLoader.hide();
                        }
                    }).catch(error => {
                        window.dialogLoader.hide();
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al realizar la búsqueda. Intente nuevamente",
                            footer: ""
                        });
                    }).then(()=>{
                        self.isLoadingSeries = false;
                        window.dialogLoader.hide();
                    });
            } catch (error) {
                console.log("ERROR al buscar: ",error);
            }
        }
    },
    //Llenamos el arreglo con la caja, anio y su folio menor, para luego en saveretiro regresar el folio autoincrementable de la caja
    busca_menor_autoinc: function(cajas_autoincrementable, venta){
        var self = this;
        var encontrado = 0;
        for(var k in cajas_autoincrementable){
            var auto = cajas_autoincrementable[k];
            var anio = parseInt(self.parse_date_anio(venta.fecha));
            if( auto['caja'] == venta.caja && auto['anio'] == anio){
                encontrado = 1;
                if(auto['folio'] > parseInt(venta.folio_int)){//Solo si es menor se reemplaza
                    auto['folio'] = parseInt(venta.folio_int);
                }
                break;
            }
        }
        if(encontrado == 0){ //Si no se encontró se agrega uno nuevo
            var model = {
                "id_sucursal": venta.sucursal._id,
                "caja":venta.caja,
                "anio": parseInt(self.parse_date_anio(venta.fecha)),
                "folio": parseInt(venta.folio_int)
            }
            cajas_autoincrementable.push(model);
        }
    },
    onlyUnique: function(value, index, array) {
      return array.indexOf(value) === index;
    },
    parse_date_time_mx(date) {
      if (!date) return null;
      return window.moment(String(date)).format("DD-MM-YYYY HH:mm");
    },
    parse_date_anio(date) {
      if (!date) return null;
      return window.moment(String(date)).format("YYYY");
    },
   
    formatNumber: function (x) {
      if (x == undefined) return 0;
      x = x.toString();
      var pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
      return x;
    },
    get_sucursales: function () {
        let data = {
            "selector": {
                "type":"sucursales",
                "estatus": "Activo"
            },
            "fields": [
                "nombre", "_id","nombre","clave"
            ]
        };

        window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
            .then(response => {
                this.sucursales = [];                
                if (response.data.docs.length > 0){
                    this.sucursales = response.data.docs;                    
                }
                    
            })

            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener las sucursales.",
                    footer: ""
                });
            });
    },
    parse_date(date) {
      if (!date) return null;
      return moment(String(date)).format("YYYY-MM-DD");
    },
    valida_fecha: function(fecha) {
      if (fecha == "Invalid date")
        return "Fecha no válida."
      else
        return true;
    },
    valida_time: function(time) {
      if(time!=null && time.toString()!=""){
            if(time.toString().length != 5)
                return "Hora no valida1";
            if(!time.match(/^\d{2}:(?:[0-5]\d)$/))
                return "Hora no valida2";
            var time_arr = time.split(":");
            if(time_arr.length!=2){
                return "Hora no valida3";
            }else{
                if(isNaN(time_arr[0]) || isNaN(time_arr[1])){
                    return "Hora no valida4";
                }
                if(parseInt(time_arr[0])<24 && parseInt(time_arr[1])<60)
                {
                    return true;
                } else{
                    return "Hora no valida5";
                }
            }
      }
      return true;

    },
    validaCantidadEntera: function(value) {
      const pattern = /^[\d]*$/;
      if (value != null && value != "" && !pattern.test(value)) {
        return "Número no válido"
      } else
        return true;
    },
    clean_model: function() {
      return {        
        fecha_de: '',        
        canceladas: "0",
        serie:[],
        sucursal:""        
      };
    },
    clean_model_corte: function() {
      return {        
        "type": "corte_caja",
        "tipo": "corte_general",
        "caja": "GENERAL", //caja de la ultima venta
        "cajero": "", //nombre del cajero
        "cajero_id": "", //email del cajero
        "sucursal": {}, 
        "fecha": "", //Fecha del día seleccionado
        "fecha_corte_general":"", 
        "supervisor": "", //usuario logueado ["_id", "nombre", "email"]
        
        "billetes_1000": 0,
        "monto_1000": 0,
        "billetes_500": 0,
        "monto_500": 0,
        "billetes_200": 0,
        "monto_200": 0,
        "billetes_100": 0,
        "monto_100": 0,
        "billetes_50": 0,
        "monto_50": 0,
        "billetes_20": 0,
        "monto_20": 0,
        "monto_monedas": 0,
        "billetes_total": 0,
        
        "efectivo_caja": 0, 
        "efectivo_retirado": 0,
        "restante_en_caja": 0, //restante efectivo

        "cantidad_voucher": 0,
        "monto_voucher": 0, //monto voucher retirado
        "cantidad_transferencia": 0,
        "monto_transferencia": 0,//monto transferencias retirado
        "cantidad_cheques": 0,
        "monto_cheques": 0,//monto cheques retirado
        "monto_total": 0,  //monto total retirado

        //Exclusivos del corte general
        "faltante":0,
        "sobrante":0,
        "observaciones":"",
      };
    },
    generar_reporte: function(){
        if(this.$refs.form.validate()){
        try {
                window.dialogLoader.show('Espere un momento por favor..');

                window.axios
                    .post(process.env.VUE_APP_REPORTES_URL + "/reportes/rpt_ventas_empleados/", this.model, {
                        "Content-type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Allow": "POST",
                        "cache-control": "no-cache",
                        "responseType": "arraybuffer"
                    })
                    .then(response => {
                        var bytes = response.data;
                        let blob = new Blob([bytes], {
                                type: response.headers["content-type"]
                            }),
                            url = window.URL.createObjectURL(blob);

                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = url;
                        a.download = "Ventas Empleados.xlsx";
                        a.click();
                        a.parentNode.removeChild(a);
                    })
                    .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al generar el reporte. Intente nuevamente.",
                        footer: ""
                    });
                    }).then(()=>{
                    window.dialogLoader.hide();
                    });
            } catch (error) {
            console.log("ERRORS: ",error);
            }
        }
    },



  }
}
</script>
